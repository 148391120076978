import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
//import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { wrapper } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Parallax from "../../components/shared/Backgrounds/Parallax";
import RegisterFormBlock from '../../components/Content/Register/RegisterFormBlock';
import ContentFromWp from '../../components/shared/ContentFromWP';

const WrapperDesktop = styled('div')`
  ${wrapper}
  h2 {
    margin-bottom: 20px;
  }
`;


/*const RelatedWrapper = styled('div')`
  padding: 30px 0;
  margin-top: 10px;
  text-align: center;
  h3 {
    font-size:1.6rem;
    margin-top:0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  li {
    width: 23%;
    text-align: left;
  }
  .gatsby-image-wrapper{
    border-radius: 10px;
  }
  .recent-name {
    font-size: 1rem;
    font-weight: ${font.headingWeight};
    margin: 20px 0 10px;
  }
  
  .recent-excerpt {
    font-size: 14px;
    line-height: 20px;
    max-height: 140px;
    overflow-y: hidden;
  }
    
  @media (max-width: ${breakpoints.md}) {
    ul {
      margin: 10px 0;
    }
    li {
      width:48%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
     text-align: left; 
     h3 {
        font-size:18px;
     }
  }
  @media (max-width: ${breakpoints.xs}) {
    li {
      width: 100%;
    }
  }
`;*/


const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';
  //const relatedPosts = data.allWordpressPost;
  //const placeholder = data.placeholder;
  //const relatedIds = pageContext.related;
  //const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
      />
      <Parallax/>
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        <h1 className="gradient a-center" dangerouslySetInnerHTML={{ __html: currentPage.title }} />

        <RegisterFormBlock/>

        {/*featuredMedia
        && featuredMedia.localFile
        && featuredMedia.localFile.childImageSharp
        && featuredMedia.localFile.childImageSharp.fluid ?
          <div>
            <FeaturedWrapper>
              <Img fluid={featuredMedia.localFile.childImageSharp.fluid}
                   alt={(currentPage.featured_media.alt_text) ? currentPage.featured_media.alt_text : currentPage.title}/>
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
            </FeaturedWrapper>
            <NoFeaturedWrapperReg>
              <div className="top-block">
                <p>เล่นเกมใหม่ฟรี</p>
                <div className="auth-container"><Auth hideRegButton={true}/></div>
              </div>
              <div className="top-block top-block-right">
                <RegistrationFormStatic games={games}/>
              </div>
            </NoFeaturedWrapperReg>
          </div>
          : <NoFeaturedWrapperReg>
            <div className="top-block">
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
              <p>เล่นเกมใหม่ฟรี</p>
              <div className="auth-container"><Auth hideRegButton={true}/></div>
            </div>
            <div className="top-block top-block-right">
              <RegistrationFormStatic games={games}/>
            </div>
          </NoFeaturedWrapperReg>*/}

        <ContentFromWp>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>
        </ContentFromWp>


        {/*relatedIds.length > 0 &&
        <Bg>
          <RelatedWrapper>
            <h3 className='h3'>
              <span>อัพเดท บทความ ข่าวสารจาก GClub</span>
            </h3>
            <ul>
              {relatedPosts.edges.map((item, index) =>
                <li key={item.node.slug + index}>
                  <Link to={"/" + item.node.slug + "/"}>
                    {(item.node.featured_media && item.node.featured_media.localFile
                      && item.node.featured_media.localFile.childImageSharp)
                      ? <div className="img-wrap">
                        <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                             alt={item.node.featured_media.alt_text ? item.node.featured_media.alt_text : item.node.title}/>
                      </div>
                      : <div className="img-wrap">
                        <Img fluid={placeholder.childImageSharp.fluid} alt={item.node.label}/>
                      </div>
                    }
                    <div className='recent-name' dangerouslySetInnerHTML={{ __html: item.node.title }}/>
                    <div className='recent-excerpt' dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
                  </Link>
                </li>
              )}
            </ul>
          </RelatedWrapper>
        </Bg>*/}
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(filter: {
      wordpress_id: {in: $related}, 
      acf: {post_template: {ne: "service_post_carousel"}}
    }, limit:4) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: {wordpress_id: {in: $contentImages}}) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }      
  }
`;