import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';
import {
  AuthFreespinNotLogged,
  getUserInformation,
  LogoutButton
} from '../../../components/Auth/Auth';

import StoreContext from '../../shared/Context/StoreContext';


const NoFeaturedWrapperReg = styled('div')`
  .top-block {
    text-align: center;
    margin-bottom: 20px;
    p {
      margin-bottom: 20px;
    }
  }
  .auth-wrapper {
    display: block;
    height: auto;
    width: auto;
    .button {
      margin-left: 0 10px;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    h4 {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    background: none;
    .button {
       width: 100%;
       margin: 5px 0 !important;
       span {
         width: 100%;
         justify-content: center;
       }  
    }
  }
`;

const RegisterFormBlock = () => {
  const value = useContext(StoreContext);
  const contextUsername = value.username;
  const [loadingUser, setLoadingUser] = useState(true);
  const [userInformation, setUserInformation] = useState({});
  useEffect(() => {
    let mounted = true;
    getUserInformation().then(data => {
      if (mounted) {
        setUserInformation(data);
        setLoadingUser(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, [contextUsername]);
  return (
    <NoFeaturedWrapperReg>
      {loadingUser ?
        <p>กำลังโหลด ...</p>
        : <>
          {userInformation.username ?
            <>
              <div className="top-block">
                <LogoutButton/>
              </div>
            </> :
            <>
              <div className="top-block">
                <p>กรุณาเข้าสู่ระบบหรือลงทะเบียน</p>
                <AuthFreespinNotLogged/>
              </div>
            </>
          }
        </>
      }
    </NoFeaturedWrapperReg>
  );
};

export default RegisterFormBlock;

